import React, { useState, useRef, useEffect } from 'react';
import { Link } from "gatsby"
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Popover = ({content}) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div ref={node} className={classNames('popover-container', {'active' : open === true})}>
      <button className="popover-button" onClick={e => setOpen(!open)}>
      </button>
      {open && (
        <div className="popover">
          {content.title && 
          <h4 className="popover-title">{content.title}</h4>
          }
          {content.list && 
            <ul className="list">
              {content.list.map ((item, i) => {
                return (
                  <li className="item" key={i}><FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> <p className="popover-item-text">{item.text}</p></li>
                )
              })}
            </ul>
          }

          {content.text && <p className="popover-text">{content.text}</p>}

          {content.buttonText && 
            <div className="btn-wrapper">
              {
                content.isExternal 
                ?
                <a href={content.url} target="_blank" rel="noopener noreferrer"
                className={classNames('btn', 
                  {"btn-orange" : content.buttonColor === "orange"},
                  {"btn-white" : content.buttonColor === "white"},
                  {"btn-black" : content.buttonColor === "black"}
                )}>{content.buttonText}</a>
                :
                <Link to={content.url}
                className={classNames('btn', 
                  {"btn-orange" : content.buttonColor === "orange"},
                  {"btn-white" : content.buttonColor === "white"},
                  {"btn-black" : content.buttonColor === "black"}
                )}>{content.buttonText}</Link>
              }
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default Popover;


