import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import Popover from './popover'
import Img from "../components/image-transform"

const SwiperTabs = (props) => {
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 'auto',
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  };

  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 12,
    loop:true,
    loopedSlides: 5,
  };

  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  return (
    <>
      <div className="section-swiper-tabs">
        <div className="container">
          <div className="text-wrapper">
            <h2 className="title">{props.title}</h2>
            <p className="description">{props.description}</p>
          </div>
        </div>
        <div className="swiper-tabs">    
         <div className="container">
           <div className="swiper-tab-title">
            <Swiper {...thumbnailSwiperParams}>
                {
                  props.tabs.map((item, i) => (
                    <div className="swiper-slide" key={i}>           
                      <span className="item-tab-title">{item.tabTitle}</span>
                    </div>
                  ))
                }
            </Swiper> 
          </div>
          </div>
          <div className="swiper-tab-content">
            <Swiper {...gallerySwiperParams}>
                {
                  props.tabs.map((item, i) => (
                    <div className="swiper-slide" key={i}>           
                      <div 
                      className={classNames('image-wrapper', 
                        {"plan-creation" : item.tab === "planCreation"},
                        {"brand-settings" : item.tab === "brandSettings"},
                        {"embed-code" : item.tab === "embedCode"},
                        {"integrated-checkout" : item.tab === "integratedCheckout"},
                        {"customer-profile" : item.tab === "customerProfiles"},
                        {"powerful-invoice" : item.tab === "powerfulInvoices"},
                        {"revenue-protection" : item.tab === "revenueProtection"},
                        {"advanced-reports" : item.tab === "advancedReports"},
                        {"exceptional-services" : item.tab === "exceptionalServices"},
                      )}
                      >
                      <div className="image-with-popover">
                        {item.otherFormat ?
                        <img src={item.image} className="image" alt={item.imageName}/>
                        :
                        <Img filename={item.image} className="image" alt={item.imageName}/>
                        }
                        <div>
                        { item.popover &&
                          item.popover.map ((popoverContent, j) => {
                            return (
                              <Popover key={j} content={popoverContent.popoverContent} />       
                            )
                          })
                        }  
                        </div>
                      </div>
                     </div>
                    </div>
                  ))
                }
            </Swiper> 
          </div>
      
            {/* <Tabs initialValue={1}>
              <TabList>
              <Swiper {...BannerSwiper}>
                {
                  content.swiperContent.map((item, i) => (
                    <div className="swiper-slide" key={i}>           
                      <Tab name={i}>
                        <div>{item.tabTitle}</div>
                      </Tab>
                    </div>
                  ))
                }
              </Swiper> 
            
              </TabList>
              {content.tabs.map((item, i) => {
                return (
                  <TabPanel name={i} key={i}>
                    <div className="image-wrapper">
                      <img src={item.image} srcSet={item.srcSet ? item.srcSet : ''} className="image" alt={item.imageName}/>
                    </div>
                  </TabPanel>
                )
              })}
            </Tabs> */}
          </div>
        </div>
      
    </>
  )
}

export default SwiperTabs