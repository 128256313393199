import React, { useState, useEffect } from "react"
import axios from 'axios'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
// import Img from "../components/image-transform"

const GCMS_TOKEN = "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE1ODk4OTI0MjEsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NrOXk4a3d1YjA3angwMXo0OG53bWV4amQvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMmY5ZjU3YzMtYWMyZi00ZGQzLWI5YTItOTdhODdlZjE4ZmFlIiwianRpIjoiY2thZHd3YnFsMDYydTAxd20yaTE4OWR6ciJ9.o79Ip3HHiuaxTLYbHPJsWtIi52w-nMVP5iv7xyGHDm0jJ-OHOB9bJbP-zj22h7XtrMiXwXLo9o6Br2O10gaHefYM9l14le8YMxkCFOc8xv8b7d6mbUY6Hh0OIyO62DQysETve9P_uQn8PIe8QS7vwivfBcs9WFQR--6HMN2XmWDTPWKp9_whRbBqQ0uVcJ83R4uqh_ivT9IjzFHbNKxAHFL7LzV_EQ96aROzMhcgOBVs7-w_EAtP1VK0s5Jmus70-AOJktEPWppICNLwX3J8I7IqY_YzTD1OcWcgqOvSPdMOS1uSgsroKJDdF6vdYWYiBkx0kaQmSQ6Q1XTnuwxd9Bo8UEKgTaQ8RG_gPMTcX6nR-q5_7uITi9T5WGK542yOJ0AC7BmtUYNdOSAqytC6ILbu10Oq5oQPzm_XPdJ_gLg7Vc55-Obq_oWPzAo5-L5aV7gJC8Onr8I-6DxxEyeXbW-oTlKrHBecebWXyMl681cn8C8YlEwbgleW8UbujOVD8xFfe2q7nhcumEaFf7SYnFc4ljmwDnljvR_5DfJgU9grg5qPfUFcALp4_CDF_g_GAjNaf8l9iT0BK0n_gzu0Jyb04nzayXH--CGvEuwGdpf2U1R2nBWIp7NoR0Alu-ctIGGr7j-qWwIA04VUdEleIcKQPGlswSRtLrtbJl9MpI0"

const BannerSwiper = {
  spaceBetween: 24,
  shouldSwiperUpdate: true,
  rebuildOnUpdate: true,
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  breakpoints: {
    991: {
      slidesPerView: 2.3
    },
    768: {
      slidesPerView: 1.5
    },
    320: {
      slidesPerView: 1,
      // spaceBetween: 0,
      // centeredSlides: false,
    }
  }
}


const Testimonial = () => {

  const [testimonialData, setTesimonialData] = useState()

  useEffect(() => {
      axios({
        method: "POST",
        url:
          "https://api-us-east-1.graphcms.com/v2/ck9y8kwub07jx01z48nwmexjd/master",
        headers: {
          "Content-Type": "application/json",
          Authorization: GCMS_TOKEN,
        },
        data: {
          query: `
            query MyQuery() {
              testimonials(first: 5, orderBy: createdAt_DESC) {
                quotation
                headshot {
                  url
                }
              }
            }    
          `,
        },
      }).then(res => {
        setTesimonialData(res)
      })
  }, []); 

  return (
    <div className="section-testimonial">
      <div className="container">
        <div className="badge-wrapper">
          {/* <a href="https://www.g2.com/products/billsby/reviews?focus=true&order=most_helpful" target="_blank">
            <img src="https://www.g2.com/products/billsby/widgets/stars?color=white&amp;type=read" height="57"/>
          </a> */}
          <a href="https://www.capterra.com/reviews/192675/Billsby?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'> <img border='0' src='https://assets.capterra.com/badge/69416cf7dc3929c9af9378c72177cf01.png?v=2134781&p=192675" target="_blank" rel="noopener noreferrer">
            {/* <Img filename="https://assets.capterra.com/badge/69416cf7dc3929c9af9378c72177cf01.png?v=2134781&p=192675" className="review-badge" alt="review badge"/> */}
            <img src="https://assets.capterra.com/badge/69416cf7dc3929c9af9378c72177cf01.png?v=2134781&p=192675" className="review-badge" alt="review badge"/>
          </a>
        </div>

      {
        testimonialData ? 
        <Swiper {...BannerSwiper}>
        {
           testimonialData.data.data.testimonials.map( (item, i) => 
            <div className="swiper-slide" key={i}>
              <div className="card-item">
              <div className="image-wrapper">
                <img src={item.headshot.url} className="image" alt="Testimonial Avatar"/>
              </div>
              <div className="text-wrapper">
               <p className="statement">{item.quotation}</p>
              </div>
              </div>
            </div>
          )
        }
        </Swiper> : ''
      }
       
      </div>
    </div>
  )
}

export default Testimonial